import $ from "jquery";

$(function() {
    if($(".bl-vorgehen").length == 0) {
        return;
    }

    const $allItems = $(".bl-vorgehen .highlightable");

    function getHighlightedElement()
    {
        const xpos = window.innerWidth*0.5;
        const ypos = window.innerHeight*0.5;

        const elements = document.elementsFromPoint(xpos, ypos);
        for(const element of elements) {
            if(element.classList.contains("highlightable")) {
                return element;
            }
        }
        return null;

    }

    function updateHighlight()
    {
        const hlel = getHighlightedElement();
        if(hlel) {
            const $hlel = $(hlel);
            if($hlel.hasClass("highlighted")) {
                //already highlighted => do nothing
                return;
            }
            $allItems.removeClass("highlighted");
            $(hlel).addClass("highlighted");
        }
    }

    window.setInterval(updateHighlight, 250);
});

