import $ from "jquery"

$(function() {
    /*
    const $menubutton = $("#menubutton");
    const $desktopmenu = $("#desktopmenu");
    const $body = $("body");
    $menubutton.on("click", function() {
        const newvisible = !$desktopmenu.hasClass("visible");
        $desktopmenu.toggleClass("visible", newvisible);
        $body.toggleClass("desktopmenuvisible", newvisible);
    });
    */

    const $menu = $("#offcanvasmenu");
    const $hamburger = $("#hamburger-offcanvasmenu");
    const $body = $("body");

    $hamburger.on("click", function() {
        toggleMenu();
    });

    function toggleMenu() {
        if(isMenuVisible()) {
            hideMenu();
        } else {
            showMenu();
        }
    }

    function isMenuVisible() {
        return $menu.hasClass("visible");
    }
    function showMenu() {
        $menu.addClass("visible");
        $hamburger.addClass("is-active");
        $body.addClass("offcanvasmenuvisible");
    }

    function hideMenu() {
        $menu.removeClass("visible");
        $hamburger.removeClass("is-active");
        $body.removeClass("offcanvasmenuvisible");
    }

    $(document).on("click", function(ev) {
        if(!isMenuVisible()) {
            return;
        }
        
        const $clickedElement = $(ev.target);
        if($clickedElement.closest(".hamburger").length == 0 && $clickedElement.closest("#offcanvasmenu").length == 0) {
            hideMenu();
        }
    });

    $menu.on("click", "a", hideMenu);
});
