import ScrollReveal from "scrollreveal";

//scrollreveal
ScrollReveal().reveal(".fade-in-top", {
  origin: "top",
  distance: "150px",
  easing: "cubic-bezier(.6,.26,.35,.74)",
  duration: 1000,
});

ScrollReveal().reveal(".fade-in", {
  easing: "cubic-bezier(.6,.26,.35,.74)",
  duration: 2000,
});

ScrollReveal().reveal(".fade-in-right", {
  origin: "right",
  distance: "150px",
  easing: "cubic-bezier(.6,.26,.35,.74)",
  duration: 1000,
});

ScrollReveal().reveal(".fade-in-left", {
  origin: "left",
  distance: "120px",
  easing: "cubic-bezier(.6,.26,.35,.74)",
  duration: 1000,
  interval: 300,
  //beforeReveal: revealCallback
});

ScrollReveal().reveal(".fade-in-bottom", {
  origin: "bottom",
  distance: "150px",
  easing: "cubic-bezier(.6,.26,.35,.74)",
  duration: 1000,
});

/*
function revealCallback(el) {
  console.log(el);
  $(el).css("outline", "5px solid yellow");
}
*/