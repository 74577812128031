import $ from "jquery";
import Isotope from "isotope-layout"; 
// npm install isotope-layout

$(function() {
    $(".bl-referenzlist").each(function() {
        const $bl = $(this);

        const $guttersizer = $bl.find(".guttersizer");
        const guttersize = $guttersizer.width();

        const iso = new Isotope(".bl-referenzlist .items", {
            itemSelector: ".item",
            masonry: {
                gutter: guttersize
            }
        });

        const $filterbuttons = $bl.find(".ctrfilterbuttons");
        $filterbuttons.on("click", ".filterbutton", function() {
            const $filterbutton = $(this);
            const requestedFilter = $filterbutton.data("filter");

            highlightFilterButton(requestedFilter);
            filterIsotope(requestedFilter);
        });

        function highlightFilterButton(requestedFilter) {
            $filterbuttons.find(".filterbutton").removeClass("active");
            $filterbuttons.find("[data-filter='"+requestedFilter+"']").addClass("active");
        }

        function filterIsotope(requestedFilter) {
            if(requestedFilter == "*") {
                clearFilterIsotope();
                return;
            }

            iso.arrange({
                filter: ".filter-"+requestedFilter
            });
        }

        function clearFilterIsotope() {
            iso.arrange({
                filter: ""
            });
        }
    
        //window.iso = iso;
        window.filterIsotope = function() {
            iso.arrange({
                filter: ".filter-idx2"
            });
        }
        window.clearFilterIsotope = function() {
            iso.arrange({
                filter: ""
            });
        }
    });
});

