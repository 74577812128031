import $ from "jquery";
import "jquery.scrollto";
// npm install jquery.scrollto

function getHeaderHeight() {
    //return $("header").outerHeight();
    return 0;
}

function findTarget(scrolltarget) {
    let $scrolltarget = $("[data-scrolltarget="+scrolltarget+"]");
    if($scrolltarget.length > 0) {
        return $scrolltarget;
    }

    $scrolltarget = $("a[name="+scrolltarget+"]");
    if($scrolltarget.length > 0) {
        return $scrolltarget;
    }

    $scrolltarget = $("#"+scrolltarget);
    if($scrolltarget.length > 0) {
        return $scrolltarget;
    }

    return null;
}

function scrollToTarget(scrolltarget) {
    const $scrolltarget = findTarget(scrolltarget);
    if($scrolltarget) {
        $(window).scrollTo($scrolltarget, 500, {
            offset: -getHeaderHeight()
        });
    }
}

function scrollToHash() {
    const hash = window.location.hash;
    if(hash.length < 2) {
        return;
    }
    const scrolltarget = hash.substring(1);
    scrollToTarget(scrolltarget);
}

$(window).on("load", scrollToHash);
$(window).on("hashchange", scrollToHash);

$(function() {
    $("a[href*='#']").on("click", function(ev) {
        const href = this.href;
        const sameurl = isSameUrl(window.location.href, href);
        if(sameurl) {
            ev.preventDefault(); //no browser internal scroll to hash
            history.pushState("", "", href);
            setTimeout(scrollToHash, 100);
        }
    });
});

function removeHashtagAndQuery(surl) {
    return surl.split("?")[0].split("#")[0];
}

function isSameUrl(surl1, surl2) {
    const url1 = new URL(surl1, window.location);
    const url2 = new URL(surl2, window.location);
    const reducedUrl1 = removeHashtagAndQuery(url1.href);
    const reducedUrl2 = removeHashtagAndQuery(url2.href);
    return reducedUrl1 == reducedUrl2;
}