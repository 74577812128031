import $ from "jquery";

function populateTerminSelect() {
    const $bl = $(".bl-forminfoabend");
    
    const termine = [];
    $bl.find(".terminlist li").each(function() {
        const $li = $(this);
        const termintext = $li.text();
        termine.push(termintext);
    });

    const $select = $bl.find("select[name=wunschtermin]")
    for(const termintext of termine) {
        $select.append('<option value="'+termintext+'">'+termintext+'</option>')
    }
}

$(function() {
    populateTerminSelect();
});